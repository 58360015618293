var live_detail = function(id_match,id_home_team,id_guest_team){
    this.id_match = id_match;
    this.id_home_team = id_home_team;
    this.id_guest_team = id_guest_team;
    var self = this;
    this.init = function(){
        $("#success_penalty_group").hide();
        if($("#success_penalty").length > 0)
            $("#success_penalty").remove();
        this.disable_all_fieldset();
        if($("#div-details").length > 0)
        {
            $.get(app.baseUrl+"/live_detail",{id_match:this.id_match})
                .done(function (data) {
                    $("#div-details").html(data);
                })
                .fail(function (data) {
                    app.error("","Errore");
                })
        }

        if($("#div-team-a").length > 0 )
        {
            $.get(app.baseUrl+"/formation",{id_match:this.id_match,id_team:this.id_home_team,dt:"team_"+this.id_home_team})
                .done(function (data) {
                    $("#div-team-a").html(data);
                })
        }

        if($("#div-team-b").length > 0 )
        {
            $.get(app.baseUrl+"/formation",{id_match:this.id_match,id_team:this.id_guest_team,dt:"team_"+this.id_guest_team})
                .done(function (data) {
                    $("#div-team-b").html(data);
                })
        }

        if($("#div-referees").length > 0 )
        {
            $.get(app.baseUrl+"/formation",{id_match:this.id_match,dt:"referee",referee:true})
                .done(function (data) {
                    $("#div-referees").html(data);
                })
        }


        this.bindCruds();
        this.bindEvents();
    };

    this.bindCruds = function(){
        var crudAzioni = new crud({
            form: 'frmLiveDetail_azioni'
        });

        var crudCambi = new crud({
            form: 'frmLiveDetail_cambi'
        });

        var crudPrimoTempo = new crud({
            form: 'frmLiveDetail_first_half'
        });
        var crudSecondoTempo = new crud({
            form: 'frmLiveDetail_second_half'
        });

        var crudPrimoSupplementare = new crud({
            form: 'frmLiveDetail_inizio_primo_supplementare'
        });

        var crudSecondoSupplementare = new crud({
            form: 'frmLiveDetail_inizio_secondo_supplementare'
        });

        var crudRigori = new crud({
            form: 'frmLiveDetail_inizio_rigori'
        });

        //non passo nel normale successSave del crud perchè non voglio ricaricare la pagina
        crudAzioni.successSave = function (form, data, reload, href, callback, hideLoader) {
            if (data.response) {
                $("#comment").val("");
                $("#minute").val("");
                $("#id_type_live_action").val("").trigger('change');
                $("#id_team").val("").trigger('change');
                $("#id_player").val("").trigger('change');
                $("#tblLiveDetail_default").data("dataTable").redrawPage();
                //chiamata per aggiornare score
                $.get(app.baseUrl+"/match/"+self.id_match+"/update_score")
                    .done(function(data){
                        if(data.response)
                        {
                            $("#score").text(data.message.score_home+" - "+data.message.score_guest);
                            self.disable_all_fieldset();
                        }
                    });
                $("#success_penalty_group").hide();
                if($("#success_penalty").length > 0)
                    $("#success_penalty").remove();

            } else {
                app.warning("", data.message);
            }

            if (!hideLoader)
                app.block(0);
        };

        crudPrimoTempo.successSave = function (form, data, reload, href, callback, hideLoader) {
            if (data.response) {
                $("#tblLiveDetail_default").data("dataTable").redrawPage();
                self.disable_all_fieldset();
            } else {
                app.warning("", data.message);
            }
            if (!hideLoader)
                app.block(0);
        };

        crudSecondoTempo.successSave = function (form, data, reload, href, callback, hideLoader) {
            if (data.response) {
                $("#tblLiveDetail_default").data("dataTable").redrawPage();
                self.disable_all_fieldset();
            } else {
                app.warning("", data.message);
            }
            if (!hideLoader)
                app.block(0);
        };

        crudPrimoSupplementare.successSave = function (form, data, reload, href, callback, hideLoader) {
            if (data.response) {
                $("#tblLiveDetail_default").data("dataTable").redrawPage();
                self.disable_all_fieldset();
            } else {
                app.warning("", data.message);
            }
            if (!hideLoader)
                app.block(0);
        };

        crudSecondoSupplementare.successSave = function (form, data, reload, href, callback, hideLoader) {
            if (data.response) {
                $("#tblLiveDetail_default").data("dataTable").redrawPage();
                self.disable_all_fieldset();
            } else {
                app.warning("", data.message);
            }
            if (!hideLoader)
                app.block(0);
        };

        crudRigori.successSave = function (form, data, reload, href, callback, hideLoader) {
            if (data.response) {
                $("#tblLiveDetail_default").data("dataTable").redrawPage();
                self.disable_all_fieldset();
            } else {
                app.warning("", data.message);
            }
            if (!hideLoader)
                app.block(0);
        };

        crudCambi.successSave = function (form, data, reload, href, callback, hideLoader) {
            if (data.response) {
                $("#comment_cambio").val("");
                $("#minute_cambio").val("");
                $("#id_player_1").val("").trigger('change');
                $("#id_player_2").val("").trigger('change');
                $("#tblLiveDetail_default").data("dataTable").redrawPage();
                self.disable_all_fieldset();
            } else {
                app.warning("", data.message);
            }

            if (!hideLoader)
                app.block(0);
        };

    };

    this.bindEvents = function () {

            //alla creazione di un nuovo dettaglio abilito i controlli
            $("[data-interaction=new_action]").unbind("click").bind("click",function () {
                //self.enable_all_fieldset();
                $("#success_penalty_group").hide();
                if($("#success_penalty").length > 0)
                    $("#success_penalty").remove();
                $.get(app.baseUrl+"/match/"+self.id_match+"/get_minute")
                    .done(function(data){
                        //se ho gia impostato l'inizio del primo tempo abilito tutte la form altrimenti solo quelle per impostare l'inizio dei tempi
                        if(data.response)
                        {
                            $("#minute").val(data.message.minutes);
                            $("#minute_cambio").val(data.message.minutes);
                            $("#date_first_half").val(data.message.first_half);
                            if(data.message.second_half != null)
                                $("#date_second_half").val(data.message.second_half);
                           //self.enable_fieldset($("#fieldset-tempi"));
                            self.enable_fieldset($("#fieldset-azioni"));
                            //self.enable_fieldset($("#fieldset-cambi"));
                        }
                        else
                        {
                            if(data.message == -1)
                            {
                                /*self.enable_fieldset($("#fieldset-tempi"));
                                if($("#date_first_half").val()=="")
                                    $("#date_first_half").val(moment().format("HH:mm"));
                                if($("#date_second_half").val() == "")
                                    $("#date_second_half").val(moment().format("HH:mm"));*/
                                $("[data-interaction=set_time]").click();
                                app.warning("","Impostare l'inizio della partita");
                            }
                            else
                                app.warning("",data.message);
                        }
                    })
            });

        $("[data-interaction=set_time]").unbind("click").bind("click",function () {
            //self.enable_all_fieldset();
            $.get(app.baseUrl+"/match/"+self.id_match+"/get_date")
                .done(function(data){
                    if(data.response)
                    {
                        $("#fieldset-tempi").find("input").attr("disabled","disabled");
                        $("#fieldset-tempi").find("button").attr("disabled","disabled");
                        var first = data.message["first_half"];
                        var second = data.message["second_half"];
                        var primo = data.message.inizio_primo_supplementare;
                        var secondo = data.message.inizio_secondo_supplementare;
                        var rigori = data.message.inizio_rigori;
                        var exit = false;
                        $("#frmLiveDetail_first_half").find("input").removeAttr("disabled");
                        $("#frmLiveDetail_first_half").find("button").removeAttr("disabled");
                        if($.trim(first)=="") {
                            $("#date_first_half").val(moment().format("HH:mm"));
                            exit = true;
                        }
                        if(!exit)
                        {
                            $("#frmLiveDetail_second_half").find("input").removeAttr("disabled");
                            $("#frmLiveDetail_second_half").find("button").removeAttr("disabled");
                            if($.trim(second) == "") {
                                $("#date_second_half").val(moment().format("HH:mm"));
                                exit = true;
                            }
                        }

                        if($("#inizio_primo_supplementare").length > 0) {
                            if(!exit)
                            {
                                $("#frmLiveDetail_inizio_primo_supplementare").find("input").removeAttr("disabled");
                                $("#frmLiveDetail_inizio_primo_supplementare").find("button").removeAttr("disabled");
                                if ($.trim(primo) == "") {
                                    $("#inizio_primo_supplementare").val(moment().format("HH:mm"));
                                    exit = true;
                                }
                            }
                            if(!exit)
                            {
                                $("#frmLiveDetail_inizio_secondo_supplementare").find("input").removeAttr("disabled");
                                $("#frmLiveDetail_inizio_secondo_supplementare").find("button").removeAttr("disabled");
                                if ($.trim(secondo) == "") {
                                    $("#inizio_secondo_supplementare").val(moment().format("HH:mm"));
                                    exit = true;
                                }
                            }
                            if(!exit)
                            {
                                $("#frmLiveDetail_inizio_rigori").find("input").removeAttr("disabled");
                                $("#frmLiveDetail_inizio_rigori").find("button").removeAttr("disabled");
                                if ($.trim(rigori) == "") {
                                    $("#inizio_rigori").val(moment().format("HH:mm"));
                                }
                            }
                        }
                    }
                });
            self.enable_fieldset($("#fieldset-tempi"));
        });

        $("[data-interaction=new_change]").unbind("click").bind("click",function () {
            //self.enable_all_fieldset();
            /*if($("#date_first_half").val()=="")
                $("#date_first_half").val(moment().format("HH:mm"));
            if($("#date_second_half").val() == "")
                $("#date_second_half").val(moment().format("HH:mm"));
*/
            $.get(app.baseUrl+"/match/"+self.id_match+"/get_minute")
                .done(function(data){
                    //se ho gia impostato l'inizio del primo tempo abilito tutte la form altrimenti solo quelle per impostare l'inizio dei tempi
                    if(data.response)
                    {
                        $("#minute").val(data.message.minutes);
                        $("#minute_cambio").val(data.message.minutes);
                        $("#date_first_half").val(data.message.first_half);
                        if(data.message.second_half != null)
                            $("#date_second_half").val(data.message.second_half);
                        //self.enable_fieldset($("#fieldset-tempi"));
                        //self.enable_fieldset($("#fieldset-azioni"));
                        self.enable_fieldset($("#fieldset-cambi"));
                    }
                    else
                    {
                        if(data.message == -1)
                        {
                            /*self.enable_fieldset($("#fieldset-tempi"));
                            if($("#date_first_half").val()=="")
                                $("#date_first_half").val(moment().format("HH:mm"));
                            if($("#date_second_half").val() == "")
                                $("#date_second_half").val(moment().format("HH:mm"));*/
                            $("[data-interaction=set_time]").click();
                            app.warning("","Impostare l'inizio della partita");
                        }
                        else
                            app.warning("",data.message);
                    }
                })
        });

        //autocompilazione della select2 dei giocatori in base alla squadra selezionata
        $("#id_team").on("select2:select",function () {
            var id_team= $("#id_team").val();
            if($.trim(id_team)!="") {
                $.get(app.baseUrl + "/team/" + id_team + "/get_data", {id_match: self.id_match})
                    .done(function (data) {
                        if (data.response) {
                            data.message.players.unshift({id:"",text:'\u00A0'});
                            $("#id_player").select2('destroy').empty().select2({
                                data: data.message.players,
                            });
                        }
                        else {
                            app.warning("", data.message);
                        }
                    })
            }
        });




    };

    //abilita tutti gli elementi (anche select2)
    this.enable_fieldset = function (fieldset)
    {
        $(fieldset).removeAttr('disabled');
        $(fieldset).find("select").removeAttr("disabled")
    };
    //disabilita tutti gli elementi (anche select2)
    this.disable_fieldset = function (fieldset)
    {
        $(fieldset).attr("disabled","disabled");
        $(fieldset).find("select").attr("disabled","disabled");
    };

    this.disable_all_fieldset = function(){
        self.disable_fieldset($("#fieldset-tempi"));
        self.disable_fieldset($("#fieldset-azioni"));
        self.disable_fieldset($("#fieldset-cambi"));
    };

    this.enable_all_fieldset = function(){
        self.enable_fieldset($("#fieldset-tempi"));
        self.enable_fieldset($("#fieldset-azioni"));
        self.enable_fieldset($("#fieldset-cambi"));
    }
};