var formatter = {

    titled: function (table, td, cell_data, row_data, row_index, col_index) {
        if (cell_data != undefined && cell_data != "")
            return '<span title="' + cell_data.replace('"', '') + '">' + cell_data + '</span>';
        else
            return "";
    },

    bool: function (table, td, cell_data, row_data, row_index, col_index) {
        return cell_data ? '<i class="fa fa-check green"></i>' : '<i class="fa fa-times red"></i>';
    },

    euro: function (table, td, cell_data, row_data, row_index, col_index) {
        return cell_data + " €";
    },

    email: function (table, td, cell_data, row_data, row_index, col_index) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (re.test(cell_data)) {
            ret = '<a href=mailto:' + cell_data + ' title="' + cell_data.replace('"', '') + '">' + cell_data + "</a>";
        } else {
            ret = cell_data;
        }

        return ret;
    },

    time_hi: function (table, td, cell_data, row_data, row_index, col_index) {
        return moment(cell_data, "hh:mm:ss").format("HH:mm");
    },

    user_actions: function (table, td, cell_data, row_data, row_index, col_index) {
        return '<button type="button" data-interaction="loginAs" data-id="' + cell_data + '" class="btn btn-white btn-link"><i class="fa fa-sign-in blue"></i></button>';
    },

    upload_avatar_comune: function(table, td, cell_data, row_data, row_index, col_index){
        if($.trim(cell_data)== '' || parseInt(cell_data)==0)
            return '';

        var r = Math.random();
        return '<button type="button" class="btn btn-link" title="Carica l\'avatar" data-callback="app.reload" data-interaction="dropzone" data-url="' + app.baseUrl + '/comune/avatar_upload/' + row_data[0] + '">Carica</button>' +
            '<img style="margin-left: 3px; width: 50px" src="' + app.baseUrl + '/comune/' + row_data[0] + '/avatar?r=' + r + '">';
    },

    upload_avatar_lista: function(table, td, cell_data, row_data, row_index, col_index){
        if($.trim(cell_data)== '' || parseInt(cell_data)==0)
            return '';

        var r = Math.random();
        return '<button type="button" class="btn btn-link" title="Carica l\'avatar" data-callback="app.reload" data-interaction="dropzone" data-url="' + app.baseUrl + '/lista/avatar_upload/' + row_data[0] + '">Carica</button>' +
            '<img style="margin-left: 3px; width: 50px" src="' + app.baseUrl + '/lista/' + row_data[0] + '/avatar?r=' + r + '">';
    },

    upload_avatar_candidato: function(table, td, cell_data, row_data, row_index, col_index){
        if($.trim(cell_data)== '' || parseInt(cell_data)==0)
            return '';

        var r = Math.random();
        return '<button type="button" class="btn btn-link" title="Carica l\'avatar" data-callback="app.reload" data-interaction="dropzone" data-url="' + app.baseUrl + '/candidato/avatar_upload/' + row_data[0] + '">Carica</button>' +
            '<img style="margin-left: 3px; width: 50px" src="' + app.baseUrl + '/candidato/' + row_data[0] + '/avatar?r=' + r + '">';
    }

};